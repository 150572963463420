import {Component, HostBinding, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ControlContent} from 'src/app/components/input-controls/abstract-control/abstract-control.component';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {RegistrationService} from 'src/app/services/registration.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {WaiverScreenStoryblok} from 'src/app/types/storyblok-component-types';
import {UrlParamsService} from 'src/app/services/url-params.service';

enum FormFields {
  signature = 'signature',
  hasMinors = 'hasMinors',
  minorsGroup = 'minorsGroup',
  minor1 = 'minor1',
  minor2 = 'minor2',
  minor3 = 'minor3',
}

@Component({
  selector: 'app-waiver',
  templateUrl: './waiver.component.html',
  styleUrls: ['./waiver.component.scss'],
})

/**
 *
 * @export
 * @class WaiverComponent
 */
export class WaiverComponent implements OnInit {
  protected content!: WaiverScreenStoryblok;
  protected formFields = FormFields;
  protected form: FormGroup;
  protected waiverScrollingComplete: boolean = false;
  protected showWaiverError: boolean = false;

  /**
   * Creates an instance of DateSelectComponent.
   * @param {FormBuilder} formBuilder
   * @param {StoryblokService} storyblokService
   * @param {DateReservationService} dateReservationService
   * @param {NavigationService} navigationService
   * @param {RegistrationService} registrationService
   * @param {OverlayNotificationService} overlayNotificationService
   */
  constructor(private formBuilder: FormBuilder,
    protected storyblokService: StoryblokService,
    protected urlParamsService: UrlParamsService,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private overlayNotificationService: OverlayNotificationService) {
    // get screen content
    this.content = this.storyblokService.typedContent.WaiverScreen![0];
    // // subscribe to content changes
    this.storyblokService.contentLoaded.subscribe(() =>
      this.content = this.storyblokService.typedContent.WaiverScreen![0],
    );

    const waiverData = registrationService.waiver;
    this.form = this.formBuilder.group({
      [FormFields.signature]: [waiverData.signature, [Validators.required]],
      [FormFields.hasMinors]: [true, [Validators.required]],
      [FormFields.minorsGroup]: new FormGroup({}),
    });
    const formGroup = this.form.get(FormFields.minorsGroup) as FormGroup;
    formGroup.addControl(FormFields.minor1, new FormControl(waiverData.minorsGroup?.minor1 || '', [Validators.required]));
    formGroup.addControl(FormFields.minor2, new FormControl(waiverData.minorsGroup?.minor2 || '', []));
    formGroup.addControl(FormFields.minor3, new FormControl(waiverData.minorsGroup?.minor3 || '', []));
  }

  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('class.has-minors-disabled')
  public get hasMinors(): boolean {
    return this.form.controls[FormFields.hasMinors].value === false;
  }

  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('class.error')
  public get showError(): boolean {
    return this.form?.touched && this.form?.invalid || this.showWaiverError;
  }

  /**
   */
  ngOnInit(): void {
    const waiver = document.querySelector('.waiver');
    const padding = 10;
    waiver?.addEventListener('scroll', () => {
      this.waiverScrollingComplete = (waiver.scrollHeight - waiver.scrollTop) <= ((waiver.clientHeight) + padding);
      if (this.showWaiverError && this.waiverScrollingComplete) {
        this.showWaiverError = false;
      }
    });
  }

  /**
   */
  protected async onSubmit() {
    this.form.markAllAsTouched();
    const invalid = !this.form.valid || !this.waiverScrollingComplete;

    if (invalid) {
      // if we have minors, mark the first minor name field as touched to potentially show error
      if (this.form.controls[FormFields.hasMinors]) {
        const minors = this.form.controls[FormFields.minorsGroup] as FormGroup;
        minors.controls[FormFields.minor1].markAsTouched();
      }
      this.showWaiverError = !this.waiverScrollingComplete;
      this.overlayNotificationService.showFormError();
    } else {
      this.registrationService.waiver = this.form.value;
      await this.registerUser();
    }
  }

  /**
   *
   * @param {number} index
   */
  protected onMinorsTabSelected(index: number) {
    const hasMinors = index === 0;
    this.form.controls[FormFields.hasMinors].setValue(hasMinors);
    if (!hasMinors) {
      this.form.controls[FormFields.minorsGroup].disable();
    } else {
      this.form.controls[FormFields.minorsGroup].enable();
    }
  }

  /**
   *
   * @private
   */
  private async registerUser() {
    this.overlayNotificationService.showLoadingOverlay();
    try {
      await this.registrationService.registerUser(this.urlParamsService.urlParams.showId);
      this.navigationService.next();
    } catch (error) {
      this.overlayNotificationService.showRegistrationError();
    }
    this.overlayNotificationService.hideLoadingOverlay();
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getInputFieldContent(id: string): ControlContent {
    return this.content.UIControls!.find((inputField) => inputField.Id === id) as ControlContent;
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getFormControl(id: string): FormControl {
    return (this.form.controls[id] ||
        (this.form.controls[FormFields.minorsGroup] as FormGroup).controls[id]) as FormControl;
  }
}
