import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlContent} from 'src/app/components/input-controls/abstract-control/abstract-control.component';
import {DateReservationService, ReservationDate} from 'src/app/services/date-reservation.service';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {RegistrationService} from 'src/app/services/registration.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {DateSelectScreenStoryblok} from 'src/app/types/storyblok-component-types';

enum FormFields {
  reservationDate = 'reservationDate',
  reservationTime = 'reservationTime',
}

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
})
/**
 *
 * @export
 * @class VipDateSelectComponent
 */
export class DateSelectComponent {
  protected content!: DateSelectScreenStoryblok;
  protected formFields = FormFields;
  protected form: FormGroup;
  protected timeslotsSelectContent: ControlContent;
  protected datePickerActiveDates: string[];

  /**
   * Creates an instance of DateSelectComponent.
   * @param {FormBuilder} formBuilder
   * @param {StoryblokService} storyblokService
   * @param {DateReservationService} dateReservationService
   * @param {NavigationService} NavigationService
   * @param {RegistrationService} registrationService
   * @param {OverlayNotificationService} errorNotification
   */
  constructor(private formBuilder: FormBuilder,
      protected storyblokService: StoryblokService,
      protected dateReservationService: DateReservationService,
      private NavigationService: NavigationService,
      private registrationService: RegistrationService,
      private errorNotification: OverlayNotificationService) {
    // get screen content
    this.content = this.storyblokService.typedContent.DateSelectScreen![0];
    // // subscribe to content changes
    this.storyblokService.contentLoaded.subscribe(() => {
      this.content = this.storyblokService.typedContent.DateSelectScreen![0];
      const dateSelectControl = this.content.UIControls!.find((inputField) => inputField.Id === FormFields.reservationTime) as ControlContent;
      this.timeslotsSelectContent.Label = dateSelectControl.Label;
      this.timeslotsSelectContent.Error = dateSelectControl.Error;
    },
    );

    // set initial timeslot content
    this.timeslotsSelectContent = this.content.UIControls!.find((inputField) => inputField.Id === FormFields.reservationTime) as ControlContent;

    // build reactive form
    const reservationData = registrationService.reservation;
    this.form = this.formBuilder.group({
      [FormFields.reservationDate]: [reservationData.reservationDate, [Validators.required]],
      [FormFields.reservationTime]: [reservationData.reservationTime, [Validators.required]],
    });

    // disable the time selection field until a date is selected
    if (!reservationData.reservationDate) {
      this.form.controls[FormFields.reservationTime].disable();
    } else {
      this.updateAvailableTimeslots(reservationData.reservationDate);
    }

    // subscribe to date changes
    this.form.controls[FormFields.reservationDate].valueChanges.subscribe((change) => {
      this.updateAvailableTimeslots(change);
      this.form.controls[FormFields.reservationTime].setValue(null);
    });

    // set active dates for date picker
    this.datePickerActiveDates = this.dateReservationService.reservationDates
        .filter((date) => date.totalQuantity > 0)
        .map((date) => date.dateLabel);
  }

  /**
   * @protected
   * @param {string} selectedDate
   */
  protected updateAvailableTimeslots(selectedDate: string): void {
    this.timeslotsSelectContent.Label = 'Select a time';
    this.timeslotsSelectContent.Answers = [];
    const selectedTimeSlot = this.dateReservationService.reservationDates.find((date) => date.dateLabel === selectedDate)!;
    for (const timeslot of selectedTimeSlot.timeslots) {
      // only add timeslots that have availability
      if (timeslot.quantity > 0) {
        this.timeslotsSelectContent.Answers!.push({AnswerCode: timeslot.id, AnswerDescription: timeslot.time});
      }
    }
    // enable the time selection field and reset it
    this.form.controls[FormFields.reservationTime].enable();
    this.form.controls[FormFields.reservationTime].markAsUntouched();
  }

  /**
   */
  protected onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.errorNotification.showFormError();
    } else {
      this.registrationService.reservation = this.form.value;
      this.NavigationService.next();
    }
    console.log(this.form.value);
  }

  /**
   * Helper for returning typed form control for template
   * @protected
   * @param {string} id
   * @return {*} {(FormControl)}
   */
  protected getFormControl(id: string): FormControl {
    return this.form.get(id) as FormControl;
  }

  /**
   * Returns the content for the date field
   * @protected
   * @param {ReservationDate} date
   * @return {*}  {ControlContent}
   */
  protected getDateFieldContent(date: ReservationDate): ControlContent {
    return {
      Id: date.dateLabel,
      // HACK tmp remove year from label
      Label: date.dateLabel.replace('2024', ''),
    };
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getInputFieldContent(id: string): ControlContent {
    return this.content.UIControls!.find((inputField) => inputField.Id === id) as ControlContent;
  }
}

