import {JsonPipe} from '@angular/common';
import {booleanAttribute, Component, Input} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaskDirective} from 'ngx-mask';
import {AbstractControlComponent, componentProvider} from '../abstract-control/abstract-control.component';

@Component({
  selector: 'app-input-text-field',
  standalone: true,
  imports: [NgxMaskDirective, ReactiveFormsModule, JsonPipe],
  templateUrl: './input-text-field.component.html',
  styleUrl: './input-text-field.component.scss',
  providers: componentProvider,
})
/**
 * @export
 * @class InputTextFieldComponent
 */
export class InputTextFieldComponent extends AbstractControlComponent {
  @Input() public inputmode: string = 'text';
}
