import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

export interface TimeSlot {
  date: string;
  time: string;
  quantity: number;
  id: string;
}

export interface ReservationDate {
  dateLabel: string;
  timeslots: TimeSlot[];
  totalQuantity: number;
}

interface ReservationTimeSlot {
  quantity: number;
  showId: string;
  date: string;
  dateLabel: string;
  id: string;
  time: string;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service for retrieving and managing reservation time slot data
 */
export class DateReservationService {
  // raw reservation time slot data
  private reservationTimeSlots: ReservationTimeSlot[] = [];
  // restructured reservation time slot data
  public reservationDates: ReservationDate[] = [];

  /**
   *
   * @param {string} showId
   * @return {*}  {Promise<void>}
   */
  public async loadAvailabilityData(showId: string): Promise<void> {
    try {
      const url = `${environment.api}/availability`;
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({showId}),
      };
      const response = await fetch(url, config);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      this.reservationTimeSlots = await response.json();
      this.reservationDates = this.parseReservationTimeSlots(this.reservationTimeSlots);
    } catch (error) {
      console.warn(error); {
      }
    }
  }

  /**
   * Utility function for converting a flat array of ReservationTimeSlot objects
   * into a more useful nested structure of ReservationDate objects.
   * @param {ReservationTimeSlot[]} reservationTimeSlots
   * @return {ReservationDates}
   */
  private parseReservationTimeSlots(reservationTimeSlots: ReservationTimeSlot[]): ReservationDate[] {
    const reservationDates: ReservationDate[] = [];

    for (const slot of reservationTimeSlots) {
      let date = reservationDates.find((d) => d.dateLabel === slot.dateLabel);
      if (!date) {
        date = {
          dateLabel: slot.dateLabel,
          totalQuantity: 0,
          timeslots: [],
        };
        reservationDates.push(date);
      }
      date.totalQuantity += slot.quantity;
      date.timeslots.push({
        date: slot.date,
        time: slot.time,
        quantity: slot.quantity,
        id: slot.id,
      });
    }

    // HACK to fix the date and time strings
    // TODO we need to backend to start using proper unix datestamps or ISO strings
    const getFixedDate = (timeslot: TimeSlot) => {
      const date = timeslot.date.split(' ')[0];
      let time = timeslot.time.split(' ')[0];
      time = time.replace('am', ' am');
      time = time.replace('pm', ' pm');
      return new Date(date + ' ' + time);
    };

    // sort the timeslots within each date
    for (const date of reservationDates) {
      date.timeslots.sort((a, b) => getFixedDate(a).getTime() < getFixedDate(b).getTime() ? -1 : 1);
    }
    // sort the reservation dates by date
    reservationDates.sort((a, b) => new Date(a.dateLabel).getTime() < new Date(b.dateLabel).getTime() ? -1 : 1);

    // TODO - remove this
    // HACK TO TEST with hardcoded dates
    for (const date of reservationDates) {
      date.dateLabel = `${date.dateLabel} 2024`.replace('May', 'August');
    }
    return reservationDates;
  }
}
