import {Component, ComponentRef, ElementRef, ViewContainerRef} from '@angular/core';
import {NavigationEnd, Route, Router, Routes} from '@angular/router';

/**
 *
 * @class RouterComponentInstance
 */
class RouterComponentInstance<C> {
  public componentReference: ComponentRef<C> | null = null;
  /**
   * Creates an instance of RouterComponentInstance.
   * @param {Route} config
   * @param {ComponentRef} componentReference
   */
  constructor(public config: Route) {
  }
}

@Component({
  selector: 'app-custom-router',
  standalone: true,
  imports: [],
  template: ``,
  styles: [`
    :host {
      position: absolute;
    }`,
  ],
})
/**
 * Custom 'router' component that animates the transition between routes
 * by sliding the routes components in and out of view, basically a carousel
 * because the angular router appears to have a bug with animations flickering
 * on route transitions....
 * @export
 * @class CustomRouterComponent
 */
export class CustomRouterComponent {
  private routerConfigs: Route[] = [];
  private routerComponents: RouterComponentInstance<any>[] = [];
  private currentRouteView: RouterComponentInstance<any> | null = null;

  /**
   * Creates an instance of CustomRouterComponent.
   * @param {Router} router
   */
  constructor(private elementRef:ElementRef,
    private router:Router,
    private viewContainer: ViewContainerRef) {
    const routerConfig = this.router.config;
    this.getRouterConfigs(routerConfig);

    for (const route of this.routerConfigs) {
      const component = route.component;
      this.routerComponents.push(new RouterComponentInstance<typeof component>(route));
    }

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onRouteUpdate(event.url);
      }
    });

    this.onRouteUpdate(router.url);
  }

  /**
   *
   * @private
   * @param {number} url
   */
  private onRouteUpdate(url: string) {
    const index = this.routerComponents.findIndex((component) => {
      // this isn't very sophisticated, but it works for now
      // ultimately there could be route collisions here
      return url.indexOf(component.config.path!) != -1;
    });
    this.showRoute(index);
  }

  /**
   *
   * @private
   * @param {number} index
   */
  private showRoute(index: number) {
    const component = this.routerComponents[index];
    if (component.componentReference === null) {
      component.componentReference = this.viewContainer.createComponent(component.config.component!);
    }
    // position the component
    const element = component.componentReference!.location.nativeElement;
    element.style.display = 'flex';
    element.style.left = `${index * 100}vw`;

    // add the component to the host DOM and position it
    this.elementRef.nativeElement.appendChild(element);
    this.elementRef.nativeElement.style.transform = `translateX(-${index * 100}vw)`;

    // if we have a current route view, animate it out
    if (this.currentRouteView) {
      const onTransitionEnd = (event:Event) => {
        if (event.target === this.elementRef.nativeElement) {
          this.elementRef.nativeElement.style.transition = 'unset';
          this.elementRef.nativeElement.removeEventListener('transitionend', onTransitionEnd);
        }
      };
      // animate container to show the component
      this.elementRef.nativeElement.style.transition = 'transform 400ms ease-in-out';
      this.elementRef.nativeElement.addEventListener('transitionend', onTransitionEnd);
    }

    this.currentRouteView = component;
  }

  /**
   * @private
   * @param {Routes} routerConfig
   * @param {string} [parentPath='']
   */
  private getRouterConfigs(routerConfig: Routes, parentPath: string = '') {
    for (const route of routerConfig) {
      if (route.component) {
        // console.log(parentPath + '/' + route.path);
        this.routerConfigs.push(route);
      }
      if (route.children) {
        this.getRouterConfigs(route.children, parentPath + '/' + route.path);
      }
    }
  }
}
