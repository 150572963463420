<form [formGroup]="$any(formControl.parent)">
  <ng-select
    [items]="content.Answers"
    bindLabel="AnswerDescription"
    [id]="content.Id"
    placeholder="{{content.Label}}"
    clearable="false"
    searchable="false"
    formControlName={{getFormControlName()}}
  >
  </ng-select>
</form>
@if(showError) {
  <div class="invalid-feedback">
      {{content.Error}}
  </div>
}