import {sleep} from './sleep';

/**
 * @export
 * @param {string} [selector='.error']
 */
export async function scrollToFirstError(selector: string = '.invalid-feedback') {
  // wait for next frame to ensure all errors are rendered
  await sleep(1);
  const firstError = document.querySelector(selector);
  if (firstError) {
    firstError.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}
