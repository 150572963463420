var _ = Object.defineProperty;
var T = (o, t, e) => t in o ? _(o, t, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: e
}) : o[t] = e;
var h = (o, t, e) => (T(o, typeof t != "symbol" ? t + "" : t, e), e);
function $(o) {
  return !(o !== o || o === 1 / 0 || o === -1 / 0);
}
function w(o, t, e) {
  if (!$(t)) throw new TypeError("Expected `limit` to be a finite number");
  if (!$(e)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    i = 0;
  const n = function () {
      i++;
      const a = setTimeout(function () {
        i--, s.length > 0 && n(), r = r.filter(function (u) {
          return u !== a;
        });
      }, e);
      r.indexOf(a) < 0 && r.push(a);
      const l = s.shift();
      l.resolve(o.apply(l.self, l.args));
    },
    c = function (...a) {
      const l = this;
      return new Promise(function (u, g) {
        s.push({
          resolve: u,
          reject: g,
          args: a,
          self: l
        }), i < t && n();
      });
    };
  return c.abort = function () {
    r.forEach(clearTimeout), r = [], s.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, c;
}
const P = function (o, t) {
    const e = {};
    for (const s in o) {
      const r = o[s];
      t.indexOf(s) > -1 && r !== null && (e[s] = r);
    }
    return e;
  },
  x = o => o === "email",
  C = () => ({
    singleTag: "hr"
  }),
  E = () => ({
    tag: "blockquote"
  }),
  S = () => ({
    tag: "ul"
  }),
  A = o => ({
    tag: ["pre", {
      tag: "code",
      attrs: o.attrs
    }]
  }),
  U = () => ({
    singleTag: "br"
  }),
  O = o => ({
    tag: `h${o.attrs.level}`
  }),
  j = o => ({
    singleTag: [{
      tag: "img",
      attrs: P(o.attrs, ["src", "alt", "title"])
    }]
  }),
  L = () => ({
    tag: "li"
  }),
  z = () => ({
    tag: "ol"
  }),
  M = () => ({
    tag: "p"
  }),
  H = o => ({
    tag: [{
      tag: "span",
      attrs: {
        ["data-type"]: "emoji",
        ["data-name"]: o.attrs.name,
        emoji: o.attrs.emoji
      }
    }]
  }),
  N = () => ({
    tag: "b"
  }),
  q = () => ({
    tag: "strike"
  }),
  I = () => ({
    tag: "u"
  }),
  F = () => ({
    tag: "strong"
  }),
  V = () => ({
    tag: "code"
  }),
  J = () => ({
    tag: "i"
  }),
  K = o => {
    const t = {
        ...o.attrs
      },
      {
        linktype: e = "url"
      } = o.attrs;
    if (x(e) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const s in t.custom) t[s] = t.custom[s];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  Q = o => ({
    tag: [{
      tag: "span",
      attrs: o.attrs
    }]
  }),
  B = () => ({
    tag: "sub"
  }),
  G = () => ({
    tag: "sup"
  }),
  W = o => ({
    tag: [{
      tag: "span",
      attrs: o.attrs
    }]
  }),
  X = o => ({
    tag: [{
      tag: "span",
      attrs: {
        style: `background-color:${o.attrs.color};`
      }
    }]
  }),
  Y = o => ({
    tag: [{
      tag: "span",
      attrs: {
        style: `background-color:${o.attrs.color}`
      }
    }]
  }),
  Z = {
    nodes: {
      horizontal_rule: C,
      blockquote: E,
      bullet_list: S,
      code_block: A,
      hard_break: U,
      heading: O,
      image: j,
      list_item: L,
      ordered_list: z,
      paragraph: M,
      emoji: H
    },
    marks: {
      bold: N,
      strike: q,
      underline: I,
      strong: F,
      code: V,
      italic: J,
      link: K,
      styled: Q,
      subscript: B,
      superscript: G,
      anchor: W,
      highlight: X,
      textStyle: Y
    }
  },
  D = function (o) {
    const t = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      e = /[&<>"']/g,
      s = RegExp(e.source);
    return o && s.test(o) ? o.replace(e, r => t[r]) : o;
  };
class v {
  constructor(t) {
    h(this, "marks");
    h(this, "nodes");
    t || (t = Z), this.marks = t.marks || [], this.nodes = t.nodes || [];
  }
  addNode(t, e) {
    this.nodes[t] = e;
  }
  addMark(t, e) {
    this.marks[t] = e;
  }
  render(t, e = {
    optimizeImages: !1
  }) {
    if (t && t.content && Array.isArray(t.content)) {
      let s = "";
      return t.content.forEach(r => {
        s += this.renderNode(r);
      }), e.optimizeImages ? this.optimizeImages(s, e.optimizeImages) : s;
    }
    return console.warn("The render method must receive an object with a content field, which is an array"), "";
  }
  optimizeImages(t, e) {
    let s = 0,
      r = 0,
      i = "",
      n = "";
    typeof e != "boolean" && (typeof e.width == "number" && e.width > 0 && (i += `width="${e.width}" `, s = e.width), typeof e.height == "number" && e.height > 0 && (i += `height="${e.height}" `, r = e.height), (e.loading === "lazy" || e.loading === "eager") && (i += `loading="${e.loading}" `), typeof e.class == "string" && e.class.length > 0 && (i += `class="${e.class}" `), e.filters && (typeof e.filters.blur == "number" && e.filters.blur >= 0 && e.filters.blur <= 100 && (n += `:blur(${e.filters.blur})`), typeof e.filters.brightness == "number" && e.filters.brightness >= -100 && e.filters.brightness <= 100 && (n += `:brightness(${e.filters.brightness})`), e.filters.fill && (e.filters.fill.match(/[0-9A-Fa-f]{6}/g) || e.filters.fill === "transparent") && (n += `:fill(${e.filters.fill})`), e.filters.format && ["webp", "png", "jpeg"].includes(e.filters.format) && (n += `:format(${e.filters.format})`), typeof e.filters.grayscale == "boolean" && e.filters.grayscale && (n += ":grayscale()"), typeof e.filters.quality == "number" && e.filters.quality >= 0 && e.filters.quality <= 100 && (n += `:quality(${e.filters.quality})`), e.filters.rotate && [90, 180, 270].includes(e.filters.rotate) && (n += `:rotate(${e.filters.rotate})`), n.length > 0 && (n = "/filters" + n))), i.length > 0 && (t = t.replace(/<img/g, `<img ${i.trim()}`));
    const c = s > 0 || r > 0 || n.length > 0 ? `${s}x${r}${n}` : "";
    return t = t.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${c}`), typeof e != "boolean" && (e.sizes || e.srcset) && (t = t.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var u, g;
      const l = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (l && l.length > 0) {
        const d = {
          srcset: (u = e.srcset) == null ? void 0 : u.map(f => {
            if (typeof f == "number") return `//${l}/m/${f}x0${n} ${f}w`;
            if (typeof f == "object" && f.length === 2) {
              let k = 0,
                R = 0;
              return typeof f[0] == "number" && (k = f[0]), typeof f[1] == "number" && (R = f[1]), `//${l}/m/${k}x${R}${n} ${k}w`;
            }
          }).join(", "),
          sizes: (g = e.sizes) == null ? void 0 : g.map(f => f).join(", ")
        };
        let y = "";
        return d.srcset && (y += `srcset="${d.srcset}" `), d.sizes && (y += `sizes="${d.sizes}" `), a.replace(/<img/g, `<img ${y.trim()}`);
      }
      return a;
    })), t;
  }
  renderNode(t) {
    const e = [];
    t.marks && t.marks.forEach(r => {
      const i = this.getMatchingMark(r);
      i && e.push(this.renderOpeningTag(i.tag));
    });
    const s = this.getMatchingNode(t);
    return s && s.tag && e.push(this.renderOpeningTag(s.tag)), t.content ? t.content.forEach(r => {
      e.push(this.renderNode(r));
    }) : t.text ? e.push(D(t.text)) : s && s.singleTag ? e.push(this.renderTag(s.singleTag, " /")) : s && s.html ? e.push(s.html) : t.type === "emoji" && e.push(this.renderEmoji(t)), s && s.tag && e.push(this.renderClosingTag(s.tag)), t.marks && t.marks.slice(0).reverse().forEach(r => {
      const i = this.getMatchingMark(r);
      i && e.push(this.renderClosingTag(i.tag));
    }), e.join("");
  }
  renderTag(t, e) {
    return t.constructor === String ? `<${t}${e}>` : t.map(r => {
      if (r.constructor === String) return `<${r}${e}>`;
      {
        let i = `<${r.tag}`;
        if (r.attrs) for (const n in r.attrs) {
          const c = r.attrs[n];
          c !== null && (i += ` ${n}="${c}"`);
        }
        return `${i}${e}>`;
      }
    }).join("");
  }
  renderOpeningTag(t) {
    return this.renderTag(t, "");
  }
  renderClosingTag(t) {
    return t.constructor === String ? `</${t}>` : t.slice(0).reverse().map(s => s.constructor === String ? `</${s}>` : `</${s.tag}>`).join("");
  }
  getMatchingNode(t) {
    const e = this.nodes[t.type];
    if (typeof e == "function") return e(t);
  }
  getMatchingMark(t) {
    const e = this.marks[t.type];
    if (typeof e == "function") return e(t);
  }
  renderEmoji(t) {
    if (t.attrs.emoji) return t.attrs.emoji;
    const e = [{
      tag: "img",
      attrs: {
        src: t.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(e, " /");
  }
}
class b {
  constructor() {
    h(this, "isCDNUrl", (t = "") => t.indexOf("/cdn/") > -1);
    h(this, "getOptionsPage", (t, e = 25, s = 1) => ({
      ...t,
      per_page: e,
      page: s
    }));
    h(this, "delay", t => new Promise(e => setTimeout(e, t)));
    h(this, "arrayFrom", (t = 0, e) => [...Array(t)].map(e));
    h(this, "range", (t = 0, e = t) => {
      const s = Math.abs(e - t) || 0,
        r = t < e ? 1 : -1;
      return this.arrayFrom(s, (i, n) => n * r + t);
    });
    h(this, "asyncMap", async (t, e) => Promise.all(t.map(e)));
    h(this, "flatMap", (t = [], e) => t.map(e).reduce((s, r) => [...s, ...r], []));
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(t, e, s) {
    const r = [];
    for (const i in t) {
      if (!Object.prototype.hasOwnProperty.call(t, i)) continue;
      const n = t[i],
        c = s ? "" : encodeURIComponent(i);
      let a;
      typeof n == "object" ? a = this.stringify(n, e ? e + encodeURIComponent("[" + c + "]") : c, Array.isArray(n)) : a = (e ? e + encodeURIComponent("[" + c + "]") : c) + "=" + encodeURIComponent(n), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us or cn
   * @return {String} The base URL of the region
   */
  getRegionURL(t) {
    const e = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn";
    switch (t) {
      case "us":
        return s;
      case "cn":
        return r;
      default:
        return e;
    }
  }
}
class tt {
  constructor(t) {
    h(this, "baseURL");
    h(this, "timeout");
    h(this, "headers");
    h(this, "responseInterceptor");
    h(this, "fetch");
    h(this, "ejectInterceptor");
    h(this, "url");
    h(this, "parameters");
    this.baseURL = t.baseURL, this.headers = t.headers || new Headers(), this.timeout = t != null && t.timeout ? t.timeout * 1e3 : 0, this.responseInterceptor = t.responseInterceptor, this.fetch = (...e) => t.fetch ? t.fetch(...e) : fetch(...e), this.ejectInterceptor = !1, this.url = "", this.parameters = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("get");
  }
  post(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("post");
  }
  put(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("put");
  }
  delete(t, e) {
    return this.url = t, this.parameters = e, this._methodHandler("delete");
  }
  async _responseHandler(t) {
    const e = /* @__PURE__ */new Map(),
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    t.status !== 204 && (await t.json().then(r => {
      s.data = r;
    }));
    for (const [r, i] of t.headers.entries()) e.set(r, i);
    return s.headers = {
      ...e
    }, s.status = t.status, s.statusText = t.statusText, s;
  }
  async _methodHandler(t) {
    let e = `${this.baseURL}${this.url}`,
      s = null;
    if (t === "get") {
      const a = new b();
      e = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(e),
      i = new AbortController(),
      {
        signal: n
      } = i;
    let c;
    this.timeout && (c = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: t,
        headers: this.headers,
        body: s,
        signal: n
      });
      this.timeout && clearTimeout(c);
      const l = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(l)) : this._statusHandler(l);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(t) {
    const e = /20[0-6]/g;
    return new Promise((s, r) => {
      if (e.test(`${t.status}`)) return s(t);
      const i = {
        message: new Error(t.statusText),
        status: t.status,
        response: Array.isArray(t.data) ? t.data[0] : t.data.error || t.data.slug
      };
      r(i);
    });
  }
}
let m = {};
const p = {};
class st {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(t, e) {
    h(this, "client");
    h(this, "maxRetries");
    h(this, "throttle");
    h(this, "accessToken");
    h(this, "cache");
    h(this, "helpers");
    h(this, "resolveCounter");
    h(this, "relations");
    h(this, "links");
    h(this, "richTextResolver");
    h(this, "resolveNestedRelations");
    if (!e) {
      const i = new b().getRegionURL,
        n = t.https === !1 ? "http" : "https";
      t.oauthToken ? e = `${n}://${i(t.region)}/v1` : e = `${n}://${i(t.region)}/v2`;
    }
    const s = new Headers();
    s.set("Content-Type", "application/json"), s.set("Accept", "application/json"), s.forEach((i, n) => {
      t.headers && t.headers[n] && s.set(n, t.headers[n]);
    });
    let r = 5;
    t.oauthToken && (s.set("Authorization", t.oauthToken), r = 3), t.rateLimit && (r = t.rateLimit), t.richTextSchema ? this.richTextResolver = new v(t.richTextSchema) : this.richTextResolver = new v(), t.componentResolver && this.setComponentResolver(t.componentResolver), this.maxRetries = t.maxRetries, this.throttle = w(this.throttledRequest, r, 1e3), this.accessToken = t.accessToken || "", this.relations = {}, this.links = {}, this.cache = t.cache || {
      clear: "manual"
    }, this.helpers = new b(), this.resolveCounter = 0, this.resolveNestedRelations = t.resolveNestedRelations || !0, this.client = new tt({
      baseURL: e,
      timeout: t.timeout || 0,
      headers: s,
      responseInterceptor: t.responseInterceptor,
      fetch: t.fetch
    });
  }
  setComponentResolver(t) {
    this.richTextResolver.addNode("blok", e => {
      let s = "";
      return e.attrs.body.forEach(r => {
        s += t(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(t) {
    return t.version || (t.version = "published"), t.token || (t.token = this.getToken()), t.cv || (t.cv = p[t.token]), Array.isArray(t.resolve_relations) && (t.resolve_relations = t.resolve_relations.join(",")), t;
  }
  factoryParamOptions(t, e) {
    return this.helpers.isCDNUrl(t) ? this.parseParams(e) : e;
  }
  makeRequest(t, e, s, r) {
    const i = this.factoryParamOptions(t, this.helpers.getOptionsPage(e, s, r));
    return this.cacheResponse(t, i);
  }
  get(t, e) {
    e || (e = {});
    const s = `/${t}`,
      r = this.factoryParamOptions(s, e);
    return this.cacheResponse(s, r);
  }
  async getAll(t, e, s) {
    const r = (e == null ? void 0 : e.per_page) || 25,
      i = `/${t}`,
      n = i.split("/"),
      c = s || n[n.length - 1],
      a = 1,
      l = await this.makeRequest(i, e, r, a),
      u = l.total ? Math.ceil(l.total / r) : 1,
      g = await this.helpers.asyncMap(this.helpers.range(a, u), d => this.makeRequest(i, e, r, d + 1));
    return this.helpers.flatMap([l, ...g], d => Object.values(d.data[c]));
  }
  post(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("post", s, e));
  }
  put(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("put", s, e));
  }
  delete(t, e) {
    const s = `/${t}`;
    return Promise.resolve(this.throttle("delete", s, e));
  }
  getStories(t) {
    return this.get("cdn/stories", t);
  }
  getStory(t, e) {
    return this.get(`cdn/stories/${t}`, e);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _cleanCopy(t) {
    return JSON.parse(JSON.stringify(t));
  }
  _insertLinks(t, e, s) {
    const r = t[e];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  _insertRelations(t, e, s, r) {
    if (s.indexOf(`${t.component}.${e}`) > -1) {
      if (typeof t[e] == "string") this.relations[r][t[e]] && (t[e] = this._cleanCopy(this.relations[r][t[e]]));else if (t[e] && t[e].constructor === Array) {
        const i = [];
        t[e].forEach(n => {
          this.relations[r][n] && i.push(this._cleanCopy(this.relations[r][n]));
        }), t[e] = i;
      }
    }
  }
  iterateTree(t, e, s) {
    const r = i => {
      if (i != null) {
        if (i.constructor === Array) for (let n = 0; n < i.length; n++) r(i[n]);else if (i.constructor === Object) {
          if (i._stopResolving) return;
          for (const n in i) (i.component && i._uid || i.type === "link") && (this._insertRelations(i, n, e, s), this._insertLinks(i, n, s)), r(i[n]);
        }
      }
    };
    r(t.content);
  }
  async resolveLinks(t, e, s) {
    let r = [];
    if (t.link_uuids) {
      const i = t.link_uuids.length,
        n = [],
        c = 50;
      for (let a = 0; a < i; a += c) {
        const l = Math.min(i, a + c);
        n.push(t.link_uuids.slice(a, l));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: c,
        language: e.language,
        version: e.version,
        by_uuids: n[a].join(",")
      })).data.stories.forEach(u => {
        r.push(u);
      });
    } else r = t.links;
    r.forEach(i => {
      this.links[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(t, e, s) {
    let r = [];
    if (t.rel_uuids) {
      const i = t.rel_uuids.length,
        n = [],
        c = 50;
      for (let a = 0; a < i; a += c) {
        const l = Math.min(i, a + c);
        n.push(t.rel_uuids.slice(a, l));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: c,
        language: e.language,
        version: e.version,
        by_uuids: n[a].join(",")
      })).data.stories.forEach(u => {
        r.push(u);
      });
    } else r = t.rels;
    r && r.length > 0 && r.forEach(i => {
      this.relations[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveStories(t, e, s) {
    var i, n;
    let r = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof e.resolve_relations < "u" && e.resolve_relations.length > 0 && (typeof e.resolve_relations == "string" && (r = e.resolve_relations.split(",")), await this.resolveRelations(t, e, s)), e.resolve_links && ["1", "story", "url"].indexOf(e.resolve_links) > -1 && ((i = t.links) != null && i.length || (n = t.link_uuids) != null && n.length) && (await this.resolveLinks(t, e, s)), this.resolveNestedRelations) for (const c in this.relations[s]) this.iterateTree(this.relations[s][c], r, s);
    t.story ? this.iterateTree(t.story, r, s) : t.stories.forEach(c => {
      this.iterateTree(c, r, s);
    }), delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(t, e, s) {
    const r = this.helpers.stringify({
        url: t,
        params: e
      }),
      i = this.cacheProvider();
    if (this.cache.clear === "auto" && e.version === "draft" && (await this.flushCache()), e.version === "published" && t != "/cdn/spaces/me") {
      const n = await i.get(r);
      if (n) return Promise.resolve(n);
    }
    return new Promise((n, c) => {
      try {
        (async () => {
          var a;
          try {
            const l = await this.throttle("get", t, e);
            let u = {
              data: l.data,
              headers: l.headers
            };
            if ((a = l.headers) != null && a["per-page"] && (u = Object.assign({}, u, {
              perPage: l.headers["per-page"] ? parseInt(l.headers["per-page"]) : 0,
              total: l.headers["per-page"] ? parseInt(l.headers.total) : 0
            })), l.status != 200) return c(l);
            if (u.data.story || u.data.stories) {
              const g = this.resolveCounter = ++this.resolveCounter % 1e3;
              await this.resolveStories(u.data, e, `${g}`);
            }
            return e.version === "published" && t != "/cdn/spaces/me" && (await i.set(r, u)), u.data.cv && e.token && (e.version == "draft" && p[e.token] != u.data.cv && (await this.flushCache()), p[e.token] = u.data.cv), n(u);
          } catch (l) {
            return c(l);
          }
        })();
      } catch {}
    });
  }
  throttledRequest(t, e, s) {
    return this.client[t](e, s);
  }
  cacheVersions() {
    return p;
  }
  cacheVersion() {
    return p[this.accessToken];
  }
  setCacheVersion(t) {
    this.accessToken && (p[this.accessToken] = t);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(t) {
            return Promise.resolve(m[t]);
          },
          getAll() {
            return Promise.resolve(m);
          },
          set(t, e) {
            return m[t] = e, Promise.resolve(void 0);
          },
          flush() {
            return m = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve(void 0);
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this;
  }
}
export { v as RichtextResolver, Z as RichtextSchema, tt as SbFetch, b as SbHelpers, st as default };