import {Component, ElementRef} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {AbstractControlComponent, componentProvider} from '../abstract-control/abstract-control.component';

@Component({
  selector: 'app-input-checkbox-button',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './input-checkbox-button.component.html',
  styleUrl: './input-checkbox-button.component.scss',
  providers: componentProvider,
})
// eslint-disable-next-line require-jsdoc
export class InputCheckboxButtonComponent extends AbstractControlComponent {
  /**
   * Creates an instance of InputTextFieldComponent.
   * @param {ElementRef} elementRef
   */
  constructor(override elementRef: ElementRef,
      protected storyblokService: StoryblokService,
  ) {
    super(elementRef);
  }
}
