import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {provideNgxMask} from 'ngx-mask';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {ErrorNotificationComponent} from './components/error-notification/error-notification.component';
import {InputCheckboxButtonComponent} from './components/input-controls/input-checkbox-button/input-checkbox-button.component';
import {InputRadioButtonComponent} from './components/input-controls/input-radio-button/input-radio-button.component';
import {InputSelectBoxComponent} from './components/input-controls/input-select-box/input-select-box.component';
import {InputTextFieldComponent} from './components/input-controls/input-text-field/input-text-field.component';
import {LanguageSelectionBarComponent} from './components/language-selection-bar/language-selection.component';
import {LoadingOverlayComponent} from './components/loading-overlay/loading-overlay.component';
import {ProgressbarComponent} from './components/progressbar/progressbar.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {TabComponent, TabGroupComponent} from './components/tab-group/tab-group.component';
import {VehicleSelectorComponent} from './components/vehicle-selector/vehicle-selector.component';
import {StoryblokButtonDirective} from './directives/storyblok-button.directive';
import {AboutYouComponent} from './screens/about-you/about-you.component';
import {DateSelectComponent} from './screens/date-select/date-select.component';
import {ScreensContainerComponent} from './screens/screens-container/screens-container.component';
import {ThankYouComponent} from './screens/thank-you/thank-you.component';
import {VehicleSelectComponent} from './screens/vehicle-select/vehicle-select.component';
import {WaiverComponent} from './screens/waiver/waiver.component';
import {WelcomeComponent} from './screens/welcome/welcome.component';
import {StoryblokImageDirective} from './directives/storyblok-image.directive';
import {CustomRouterComponent} from './screens/screens-container/custom-router/custom-router.component';

@NgModule({
  declarations: [
    AppComponent,
    LanguageSelectionBarComponent,
    WelcomeComponent,
    ScreensContainerComponent,
    AboutYouComponent,
    ProgressbarComponent,
    VehicleSelectComponent,
    WaiverComponent,
    DateSelectComponent,
    ThankYouComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    StoryblokButtonDirective,
    InputTextFieldComponent,
    InputRadioButtonComponent,
    InputCheckboxButtonComponent,
    InputSelectBoxComponent,
    NgSelectModule,
    VehicleSelectorComponent,
    ErrorNotificationComponent,
    TabGroupComponent,
    TabComponent,
    DatePickerComponent,
    LoadingOverlayComponent,
    SpinnerComponent,
    StoryblokImageDirective,
    CustomRouterComponent,
  ],
  providers: [provideNgxMask()],
  bootstrap: [AppComponent],
})
/**
 *
 * @export
 * @class AppModule
 */
export class AppModule { }
