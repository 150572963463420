import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {UrlParams, UrlParamsService} from './url-params.service';

export const urlParamsResolver: ResolveFn<UrlParams> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const urlParamsService = inject(UrlParamsService);
      const urlParams = {
        selectedLanguage: route.paramMap.get('selectedLanguage')!,
        showId: route.paramMap.get('showId')!,
      };
      console.log('urlParamsResolver', urlParams);
      urlParamsService.set(urlParams);
      return urlParamsService.urlParams;
    };
