@if(showError) {
    <div class="invalid-feedback">
        {{error}}
        <img src="./assets/img/alert.svg">
    </div>
}
@for (vehicle of vehicles; track vehicle) {
    <div class="vehicle">
        <img
            (click)="onImageClick(vehicle.name!)" 
            [src]="vehicle.image + '/m/200x0/filters:quality(60)'">
        <input
            type="checkbox"
            (click)="onSelected(vehicle.name!)"
            [formControl]="getFormControl(vehicle.name!)"
            [value]="vehicle.name"
            [id]="vehicle.name">
        <label [for]="vehicle.name" class="text">{{vehicle.name}}</label>
    </div>
}