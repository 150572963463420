/**
 *
 * @private
 * @return {*}  {boolean}
 * @memberof AccountPageComponent
 */
export function isApplePlatform(): boolean {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
      isSafari
  );
}

/**
 *
 * @private
 * @param {string} uri
 * @memberof AccountPageComponent
 */
export function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 *
 * @private
 * @return {*}  {boolean}
 * @memberof AccountPageComponent
 */
export function isAndriodPlatform(): boolean {
  return (
    navigator.userAgent.includes('Android') ||
      navigator.platform.includes('Android')
  );
}


/**
 *
 *
 * @class Regex
 */
export class Regex {
  public static zipcode = '^[0-9]{5}(?:-[0-9]{4})?$';
}
