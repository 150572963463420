import {Component} from '@angular/core';
import {NG_VALUE_ACCESSOR, RadioControlValueAccessor, ReactiveFormsModule} from '@angular/forms';
import {AbstractControlComponent} from '../abstract-control/abstract-control.component';

export const componentProvider = [{
  provide: NG_VALUE_ACCESSOR,
  useClass: RadioControlValueAccessor,
  multi: true,
}];

@Component({
  selector: 'app-input-radio-button',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './input-radio-button.component.html',
  styleUrl: './input-radio-button.component.scss',
  providers: componentProvider,
})

/**
 * InputRadioButtonComponent
 *
 * @export
 * @class InputRadioButtonComponent
 * @extends {AbstractControlComponent}
 */
export class InputRadioButtonComponent extends AbstractControlComponent {
}

