import {getPlatform} from '@angular/core';
import Storyblok from 'storyblok-js-client/dist/types';
import {StoryblokService} from '../services/storyblok.service';

/**
 *
 * @template T
 * @param {T} defaultValue
 * @return {*}
 */
export function SubscribeToContent<T>() {
  // console.log('SubscribeToContent', getPlatform());
  // console.log('SubscribeToContent', getPlatform()?.injector);
  // console.log('SubscribeToContent', getPlatform()?.injector.get(StoryblokService));
  const storyblokService = getPlatform()?.injector.get(StoryblokService);

  //   this.content = this.storyblokService.typedContent.AboutYouScreen[0];
  //   // subscribe to content changes
  //   this.storyblokService.contentLoaded.subscribe(() =>
  //     this.content = this.storyblokService.typedContent.AboutYouScreen[0],
  //   );

  return function(target: any, key: string) {
    // console.log('SubscribeToContent', getPlatform());
    // console.log('SubscribeToContent', getPlatform()?.injector);
    // console.log('SubscribeToContent', getPlatform()?.injector.get(StoryblokService));
    let value = storyblokService?.typedContent[key];

    Object.defineProperty(target, key, {
      get: function() {
        return value as T;
      },
      set: function(newValue) {
        value = newValue;
      },
      enumerable: true,
      configurable: true,
    });
  };
}

// class ExampleComponent {
//     @DefaultValue('John')
//       name: string;

//     constructor() {
//       console.log(this.name); // Output: 'John'
//     }
// }

// const exampleComponent = new ExampleComponent();
