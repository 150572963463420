import { DateTime as t } from "@easepick/datetime";
import { BasePlugin as i } from "@easepick/base-plugin";
class s extends i {
  priority = 1;
  binds = {
    onView: this.onView.bind(this)
  };
  options = {
    minDate: null,
    maxDate: null,
    minDays: null,
    maxDays: null,
    selectForward: null,
    selectBackward: null,
    presets: !0,
    inseparable: !1,
    filter: null
  };
  getName() {
    return "LockPlugin";
  }
  onAttach() {
    if (this.options.minDate && (this.options.minDate = new t(this.options.minDate, this.picker.options.format, this.picker.options.lang)), this.options.maxDate && (this.options.maxDate = new t(this.options.maxDate, this.picker.options.format, this.picker.options.lang), this.options.maxDate instanceof t && this.picker.options.calendars > 1 && this.picker.calendars[0].isSame(this.options.maxDate, "month"))) {
      const t = this.picker.calendars[0].clone().subtract(1, "month");
      this.picker.gotoDate(t);
    }
    if ((this.options.minDays || this.options.maxDays || this.options.selectForward || this.options.selectBackward) && !this.picker.options.plugins.includes("RangePlugin")) {
      const t = ["minDays", "maxDays", "selectForward", "selectBackward"];
      console.warn(`${this.getName()}: options ${t.join(", ")} required RangePlugin.`);
    }
    this.picker.on("view", this.binds.onView);
  }
  onDetach() {
    this.picker.off("view", this.binds.onView);
  }
  onView(i) {
    const {
      view: s,
      target: e,
      date: a
    } = i.detail;
    if ("CalendarHeader" === s && (this.options.minDate instanceof t && a.isSameOrBefore(this.options.minDate, "month") && e.classList.add("no-previous-month"), this.options.maxDate instanceof t && a.isSameOrAfter(this.options.maxDate, "month") && e.classList.add("no-next-month")), "CalendarDay" === s) {
      const t = this.picker.datePicked.length ? this.picker.datePicked[0] : null;
      if (this.testFilter(a)) return void e.classList.add("locked");
      if (this.options.inseparable) {
        if (this.options.minDays) {
          const t = a.clone().subtract(this.options.minDays - 1, "day"),
            i = a.clone().add(this.options.minDays - 1, "day");
          let s = !1,
            o = !1;
          for (; t.isBefore(a, "day");) {
            if (this.testFilter(t)) {
              s = !0;
              break;
            }
            t.add(1, "day");
          }
          for (; i.isAfter(a, "day");) {
            if (this.testFilter(i)) {
              o = !0;
              break;
            }
            i.subtract(1, "day");
          }
          s && o && e.classList.add("not-available");
        }
        this.rangeIsNotAvailable(a, t) && e.classList.add("not-available");
      }
      this.dateIsNotAvailable(a, t) && e.classList.add("not-available");
    }
    if (this.options.presets && "PresetPluginButton" === s) {
      const i = new t(Number(e.dataset.start)),
        s = new t(Number(e.dataset.end)),
        a = s.diff(i, "day"),
        o = this.options.minDays && a < this.options.minDays,
        n = this.options.maxDays && a > this.options.maxDays;
      (o || n || this.lockMinDate(i) || this.lockMaxDate(i) || this.lockMinDate(s) || this.lockMaxDate(s) || this.rangeIsNotAvailable(i, s)) && e.setAttribute("disabled", "disabled");
    }
  }
  dateIsNotAvailable(t, i) {
    return this.lockMinDate(t) || this.lockMaxDate(t) || this.lockMinDays(t, i) || this.lockMaxDays(t, i) || this.lockSelectForward(t) || this.lockSelectBackward(t);
  }
  rangeIsNotAvailable(t, i) {
    if (!t || !i) return !1;
    const s = (t.isSameOrBefore(i, "day") ? t : i).clone(),
      e = (i.isSameOrAfter(t, "day") ? i : t).clone();
    for (; s.isSameOrBefore(e, "day");) {
      if (this.testFilter(s)) return !0;
      s.add(1, "day");
    }
    return !1;
  }
  lockMinDate(i) {
    return this.options.minDate instanceof t && i.isBefore(this.options.minDate, "day");
  }
  lockMaxDate(i) {
    return this.options.maxDate instanceof t && i.isAfter(this.options.maxDate, "day");
  }
  lockMinDays(t, i) {
    if (this.options.minDays && i) {
      const s = i.clone().subtract(this.options.minDays - 1, "day"),
        e = i.clone().add(this.options.minDays - 1, "day");
      return t.isBetween(s, e);
    }
    return !1;
  }
  lockMaxDays(t, i) {
    if (this.options.maxDays && i) {
      const s = i.clone().subtract(this.options.maxDays, "day"),
        e = i.clone().add(this.options.maxDays, "day");
      return !t.isBetween(s, e);
    }
    return !1;
  }
  lockSelectForward(t) {
    if (1 === this.picker.datePicked.length && this.options.selectForward) {
      const i = this.picker.datePicked[0].clone();
      return t.isBefore(i, "day");
    }
    return !1;
  }
  lockSelectBackward(t) {
    if (1 === this.picker.datePicked.length && this.options.selectBackward) {
      const i = this.picker.datePicked[0].clone();
      return t.isAfter(i, "day");
    }
    return !1;
  }
  testFilter(t) {
    return "function" == typeof this.options.filter && this.options.filter(t, this.picker.datePicked);
  }
}
export { s as LockPlugin };