import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

/**
 * @export
 * @class AboutUser
 */
export class User {
  firstname: string | null = null;
  lastname: string | null = null;
  email: string | null = null;
  phonenumber: string | null = null;
  street: string | null = null;
  apt: string | null = null;
  city: string | null = null;
  state: string | null = null;
  zipcode: string | null = null;
  acceptTerms: string | null = null;
}

/**
 * @export
 * @class BuyingIntention
 */
export class BuyingIntention {
  nextAcquisition: string | null = null;
  vehiclesOfInterest: string[] = [];
}

/**
 * @export
 * @class ReservationDate
 */
export class ReservationDate {
  reservationDate: string | null = null;
  reservationTime: string | null = null;
}

/**
 * @export
 * @class ReservationDate
 */
export class Waiver {
  signature: string | null = null;
  hasMinors: boolean = false;
  minorsGroup: {
    minor1: string | null;
    minor2: string | null;
    minor3: string | null;
  } = {
      minor1: null,
      minor2: null,
      minor3: null,
    };
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service for persisting registration data and data submitting to API
 */
export class RegistrationService {
  // user data model
  public user: User = new User();
  public buyingIntention: BuyingIntention = new BuyingIntention();
  public reservation: ReservationDate = new ReservationDate();
  public waiver: Waiver = new Waiver();
  public ssoComplete: boolean = false;

  /**
   * Check if user is already registered for this show Id
   *
   * @param {string} showId
   * @return {*}  {boolean}
   */
  public alreadyRegistered(showId: string): boolean {
    return localStorage.getItem(showId) !== null;
  };

  /**
   * @param {string} showId
   */
  public async registerUser(showId: string) {
    const body: any = {
      email: this.user.email || 'test@imagination.com',
    };

    try {
      const url = `${environment.api}/register`;
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, config);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const result = await response.json();
      const userId = result.profileData.resource_id;
      this.saveProfileId(showId, userId);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  /**
   * Save the profile id to the local storage
   *
   * @private
   * @param {string} showId
   * @param {string} userId
   */
  private saveProfileId(showId: string, userId: string) {
    localStorage.setItem(showId, userId);
  }
}
