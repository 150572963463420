<div class="h1">{{content.Header}}</div>

<div class="page-layout">
    <div class="column left">
        <div class="question-text">{{content.PurchasePlanQuestion}}</div>
        <app-input-select-box
            [visibleItems]="4"
            [content]="content.PurchasePlan![0]"
            [formControl]="getFormControl(formFields.nextAcquisition)"
        ></app-input-select-box>
    </div>

    <div class="column right">
        <div class="question-text pad-right">{{content.VehicleOfInterestQuestion}}</div>
        <div class="info-text">{{content.VehicleOfInterestPrompt}}</div>
        <app-vehicle-selector
            error={{content.VehicleOfInterestError}}
            [vehicles]="content.Vehicles"
            [formGroup]="getFormGroup(formFields.vehiclesOfInterest)"
        ></app-vehicle-selector>
    </div>

    <div class="submit">
        <button class="btn" type="button" (click)="next()">
            {{storyblokService.typedContent.UILabels![0].NextBtn}}
        </button>
    </div>
</div>