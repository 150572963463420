<div class="page-layout">
    <div class="hero-text">
        <div class="h1">{{content.Header}}</div>
    </div>
    <div class="body-text" [innerHTML]="storyblokService.renderMarkup(content.Body)"></div>
    <div class="qr-code-container">
        <img class="qr-code" src={{qrCodeImgSrc}}>

        <!-- If we need an add to wallet button - otherwise remove -->
        @if(showAddToAppleWallet || showAddToGoogleWallet) {
            <div class="wallet-btns">
                @if(showAddToAppleWallet){
                    <button (click)="onAddToAppleWallet()">
                        <img src="./assets/img/thank-you/Add_to_Apple_Wallet.svg">
                    </button>
                }
                @if(showAddToGoogleWallet){ 
                    <button (click)="onAddToGoogleWallet()">
                        <img src="./assets/img/thank-you/Add_to_Google_Wallet.svg">
                    </button>
                }
            </div>
        }
    </div>
</div>
