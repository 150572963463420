
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';

/**
 *
 * @export
 * @param {number} [minRequired=1]
 * @return {*}  {ValidatorFn}
 */
export function minimumSelectedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: AbstractControl) {
    let checked = 0;

    Object.keys((formGroup as FormGroup).controls).forEach((key) => {
      const control = (formGroup as FormGroup).controls[key];
      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}
