import {Component, HostBinding} from '@angular/core';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {SpinnerComponent} from '../spinner/spinner.component';

@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './loading-overlay.component.html',
  styleUrl: './loading-overlay.component.scss',
})
/**
 *
 * @export
 * @class LoadingOverlayComponent
 */
export class LoadingOverlayComponent {
  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('class.visible')
  private visible: boolean = false;
  /**
   * Creates an instance of ErrorNotificationComponent.
   * @param {StoryblokService} storyblokService
   */
  constructor(
      private overlayNotificationService: OverlayNotificationService) {
    this.overlayNotificationService.showLoaderEvent.subscribe(() => {
      this.show();
    });
    this.overlayNotificationService.hideLoaderEvent.subscribe(() => {
      this.hide();
    });
  }

  /**
   */
  private show() {
    this.visible = true;
  }

  /**
   * @private
  */
  private hide() {
    this.visible = false;
  }
}
