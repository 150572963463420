import {Component, ElementRef, HostListener} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {env} from 'process';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-language-selection-bar',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
/**
 *
 * @export
 * @class LanguageSelectionBarComponent
 */
export class LanguageSelectionBarComponent {
  protected showLanguageSelector: boolean = false;
  protected hideBgColor: boolean = false;
  protected version: string = '';
  protected versionClickCount: number = 0;
  protected versionClickReveal: number = 5;

  /**
   * Creates an instance of LanguageSelectionBarComponent.
   * @param {Router} router
   * @param {StoryblokService} storyblokService
   */
  constructor(router: Router,
    protected elementRef: ElementRef,
    protected storyblokService: StoryblokService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkUrlForScreen(val.url);
      }
    });

    this.checkUrlForScreen(router.url);
  }

  /**
   *
   */
  protected openLanguageDropdown(): void {
    this.showLanguageSelector = !this.showLanguageSelector;
  }

  /**
   *
   * @param {*} lang
   */
  protected selectLangage(lang: string): void {
    this.showLanguageSelector = false;
    this.storyblokService.selectLangage(lang);
  }

  /**
   *
   * @private
   * @param {string} url
   */
  private checkUrlForScreen(url:string): void {
    this.hideBgColor = url.indexOf('/welcome') !== -1 || url.indexOf('thank-you') !== -1;
  }

  /**
   * Check if the pointer is outside the element
   * @param {*} event
   */
  @HostListener('document:pointerdown', ['$event'])
  private onPointerDown(event: PointerEvent): void {
    if (!this.showLanguageSelector) return;
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showLanguageSelector = false;
    }
  }

  /**
   * Toggle version number
   *
   * @protected
   */
  protected versionToggle() {
    if (++this.versionClickCount % this.versionClickReveal === 0) {
      this.version = environment.version;
    } else {
      this.version = '';
    }
  }
}
