
<div class="h1">{{content.Header}}</div>

<div class="page-layout">
    <form class="form" [formGroup]="form">
        <div class="row">
            <app-input-text-field
                [content]="getInputFieldContent(formFields.firstname)"
                [formControl]="getFormControl(formFields.firstname)">
            </app-input-text-field>
            <app-input-text-field
                [content]="getInputFieldContent(formFields.lastname)"
                [formControl]="getFormControl(formFields.lastname)">
            </app-input-text-field>
        </div>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.email)"
            [formControl]="getFormControl(formFields.email)">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.phonenumber)"
            [formControl]="getFormControl(formFields.phonenumber)"            
            mask="(000) 000-0000" inputmode="tel">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.street)"
            [formControl]="getFormControl(formFields.street)">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.apt)"
            [formControl]="getFormControl(formFields.apt)">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.city)"
            [formControl]="getFormControl(formFields.city)">
        </app-input-text-field>
        <div class="row">
            <app-input-text-field
                [content]="getInputFieldContent(formFields.state)"
                [formControl]="getFormControl(formFields.state)">
            </app-input-text-field>
            <app-input-text-field
                [content]="getInputFieldContent(formFields.zipcode)"
                [formControl]="getFormControl(formFields.zipcode)"
                mask="00000" inputmode="numeric">
            </app-input-text-field>
        </div>
        <app-input-checkbox-button 
            [content]="getInputFieldContent(formFields.acceptTerms)"
            [formControl]="getFormControl(formFields.acceptTerms)">
        </app-input-checkbox-button>
        <div class="privacy-policy-link"
            [innerHTML]="storyblokService.renderMarkup(content.PrivacyPolicyLink)"></div>
        <button class="btn" type="button" (click)="onSubmit()">
            {{storyblokService.typedContent.UILabels![0].NextBtn}}    
        </button>
    </form>
    <div class="hero-image" style.background-image="url('{{content.HeroImage.filename}}')" appStoryblokImage></div>
</div>
