import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ButtonStoryblok} from '../types/storyblok-component-types';
import {StoryblokService} from '../services/storyblok.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appStoryblokImage]',
  exportAs: 'appStoryblokImage',
  standalone: true,
})
/**
 * Automatically replaces background-image urls with Storyblok
 * image service urls for automatic image optimization
 * @export
 * @class StoryblokButtonDirective
 */
export class StoryblokImageDirective implements OnInit {
  readonly MAX_WIDTH = 1920;
  readonly FILTERS = [
    'quality(80)',
    // 'grayscale()',
    'format(webp)',
  ];


  /**
   * Creates an instance of StoryblokImageDirective.
   * @param {ElementRef} el
   */
  constructor(
     private el: ElementRef) {
  }

  /**
   */
  ngOnInit() {
    // TODO put logic in here to test whether img tag with src or background-image
    const originalUrl = this.el.nativeElement.style.backgroundImage;
    const rawUrl = this.removeUrlFromAttribute(originalUrl);
    // calculate the width and height of the image
    const width = Math.min(window.innerWidth, this.MAX_WIDTH);
    const height = 0;
    const size = `${width}x${height}`;
    // add filters
    const serviceUrl = `${rawUrl}/m/${size}/filters:${this.FILTERS.join(':')}`;
    this.el.nativeElement.style.backgroundImage = `url('${serviceUrl}')`;
  }

  /**
   *
   * @private
   * @param {string} originalUrl
   * @return {*}  {string}
   */
  private removeUrlFromAttribute(originalUrl: string): string {
    const urlBgExtract = new RegExp(/url\("(.+?)"\)/s);
    const rawUrl = urlBgExtract.exec(originalUrl)?.[1];
    return rawUrl || originalUrl;
  }
}
