<div class="stepper">
    <button class="previous-arrow" (click)="navigationService.previous()">
        <!-- left arrow graphic -->
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.12268 13.6227L2.00001 7.5L8.12268 1.37733" stroke="#066FEF" stroke-width="1.74933"/>
        </svg>
    </button>
    <div class="label current">
        0{{currentStep}}
    </div>
    <div class="progressbar">
        <div class="progressbar-total"></div>
        <div class="progressbar-complete" 
            [style.width.%]="barWidthPercent"></div>
    </div>
    <div class="label total">
        0{{navigationService.totalRegistrationSteps}}
    </div>
</div>
