import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {RegistrationService} from './registration.service';

interface SsoResult {
  firstname: string;
  lastname: string;
  email: string;
}

/**
 * Path resolver for single sign-on - gets url parameters
 * for a single sign-on login res string, if found it decodes it
 * and sets the user's first name, last name, and email in the
 * registration service
 * @return {ResolveFn<SsoResult | null>}
 */
export const ssoResultResolver: ResolveFn<SsoResult | null> =
    () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ssoResult = urlParams.get('res');
      if (ssoResult) {
        const registrationService = inject(RegistrationService);
        const decodedResult = JSON.parse(atob(ssoResult)) as SsoResult;
        registrationService.user.firstname = decodedResult.firstname;
        registrationService.user.lastname = decodedResult.lastname;
        registrationService.user.email = decodedResult.email;
        registrationService.ssoComplete = true;
        return decodedResult;
      }
      return null;
    };
