<div class="h1">{{content.Header}}</div>

<div class="page-layout">

    <div class="column left">
        <div class="title1">{{content.Title}}</div>
        <div class="title2">{{content.Subtitle}}</div>
    </div>
    
    <div class="column mid">
        <app-tab-group>
            
            <!-- Calendar View -->
            <tab [content]="getInputFieldContent('calendarView')">
                <app-date-picker
                    [activeDates]="datePickerActiveDates"
                    [content]="getInputFieldContent('reservationDate')"
                    [formControl]="getFormControl(formFields.reservationDate)"
                ></app-date-picker>
            </tab>

            <!-- List View -->
            <tab [content]="getInputFieldContent('listView')">
                @for (date of dateReservationService.reservationDates; track $index) {
                    @if(date.totalQuantity > 0) {
                        <div>
                            <app-input-radio-button
                                [content]="getDateFieldContent(date)"
                                [formControl]="getFormControl(formFields.reservationDate)"
                                >
                            </app-input-radio-button>
                        </div>
                    }
                }
            </tab>
        </app-tab-group>

        <!-- time selection drop down -->
        <app-input-select-box
            [content]="timeslotsSelectContent"
            [formControl]="getFormControl(formFields.reservationTime)"
        ></app-input-select-box>
    </div>

    <div class="column right">
        <div class="hero-image" style.background-image="url('{{content.HeroImage.filename}}')" appStoryblokImage>
        </div>
    </div>

    <div class="submit">
        <button class="btn" type="button" (click)="onSubmit()">
            {{storyblokService.typedContent.UILabels![0].NextBtn}}
        </button>
    </div>

    <footer class="footer-note">
        <div class="title">{{content.FooterTitle}}</div>
        <div class="body" [innerHTML]="storyblokService.renderMarkup(content.FooterBody)"></div>
    </footer>
</div>