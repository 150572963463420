import {EventEmitter, Injectable} from '@angular/core';

export interface UrlParams{
  selectedLanguage: string;
  showId: string;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service for storing the applications url parameters
 */
export class UrlParamsService {
  public updated: EventEmitter<UrlParams> = new EventEmitter();
  public urlParams!: UrlParams;

  /**
   *
   * @param {UrlParams} urlParams
   * @param {string} path
   */
  public set(urlParams: UrlParams) {
    this.urlParams = urlParams;
    this.updated.emit(this.urlParams);
  }
}
