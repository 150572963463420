<input 
    type="text"
    [id]="content.Id"
    [formControl]="formControl"
    name={{content.Id}}
    mask={{mask}}
    placeholder={{content.Label}}
    autocomplete={{content.Autocomplete}}
    [attr.inputmode]="inputmode"
>
<label [for]="content.Id">{{content.Label}}</label>
@if(showError) {
    <div class="invalid-feedback">
        {{content.Error}}
    </div>
}