import {Component, OnInit} from '@angular/core';
import {StoryblokService} from './services/storyblok.service';
import {UrlParamsService} from './services/url-params.service';
import {GooglePlacesService} from './services/google-places.service';
import {DateReservationService} from './services/date-reservation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
/**
 *
 * @export
 * @class AppComponent
 */
export class AppComponent implements OnInit {
  public loading = true;

  /**
   * Creates an instance of AppComponent.
   * @param {StoryblokService} storyblokService
   */
  constructor(public storyblokService: StoryblokService,
    private urlParamsService: UrlParamsService,
    private dateReservationService: DateReservationService,
    private googlePlacesService: GooglePlacesService) {
  }

  /**
   *
   * @return {*}  {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    this.urlParamsService.updated.subscribe(async ()=>{
      await this.loadContent();
    });
  }

  /**
   *
  */
  async loadContent(): Promise<void> {
    const urlParams = this.urlParamsService.urlParams;
    await this.storyblokService.getContentStory(urlParams.selectedLanguage);
    await this.dateReservationService.loadAvailabilityData('employee-drive'); // (urlParams.showId);
    await this.googlePlacesService.loadAPI();
    this.loading = false;
  }
}
