<div class="headerbar" [ngClass]="{'blue': !hideBgColor}">
    <div class="headerbar-icon" (click)="versionToggle()">
        <img src="./assets/img/ford-logo.svg" />
    </div>
    <div class="version">{{version}}</div>
    <button class="headerbar-icon cursor-pointer" (click)="openLanguageDropdown()">
        <img class="globe" src="./assets/img/globe.svg" />
    </button>
</div>

<div class="language-selector" [ngClass]="{'onscreen':showLanguageSelector, 'blue': !hideBgColor}">
    <button (click)="selectLangage('en')" class="btn outline narrow">
        English
    </button>
    <button (click)="selectLangage('es-mx')" class="btn outline narrow">
        Spanish
    </button>
</div>

<app-stepper [ngClass]="{'hidden': hideBgColor}"></app-stepper>