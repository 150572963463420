<div class="input-container">
    <input
        type="checkbox"
        [id]="content.Id"
        [formControl]="formControl"
        name={{content.Id}}
    >
    <label
        [for]="content.Id"
        [innerHTML]="storyblokService.renderMarkup(content.Label)">
    </label>
</div>
@if(showError) {
    <div class="invalid-feedback">
        {{content.Error}}
    </div>
}