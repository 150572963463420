import {EventEmitter, Injectable} from '@angular/core';
import {scrollToFirstError} from '../utils/scrollToFirstError';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for dispatching overlay notifications
 */
export class OverlayNotificationService {
  public showFormErrorEvent: EventEmitter<void> = new EventEmitter<void>();
  public showRegistrationErrorEvent: EventEmitter<void> = new EventEmitter<void>();
  public showLoaderEvent: EventEmitter<void> = new EventEmitter<void>();
  public hideLoaderEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   */
  public async showFormError() {
    scrollToFirstError();
    this.showFormErrorEvent.emit();
  }

  /**
   */
  public async showRegistrationError() {
    scrollToFirstError();
    this.showRegistrationErrorEvent.emit();
  }

  /**
   */
  public async showLoadingOverlay() {
    this.showLoaderEvent.emit();
  }

  /**
   */
  public async hideLoadingOverlay() {
    this.hideLoaderEvent.emit();
  }
}
