import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {RegistrationService} from '../services/registration.service';
import {RoutePaths} from '../app-routing.module';

/**
 * Route guard to check if user already registered.
 *
 * If any route except thank-you is accessed and user is already registered,
 * then redirect to thank-you page.
 *
 * @export
 * @param {ActivatedRouteSnapshot} childRoute
 * @return {*}
 */
export function checkAleadyRegistered(childRoute: ActivatedRouteSnapshot) {
  const registrationService = inject(RegistrationService);
  const alreadyRegistered = registrationService.alreadyRegistered(childRoute.params['showId']);
  const thankYouScreenRequested = childRoute.routeConfig!.path === RoutePaths.THANK_YOU;

  // if user is already registered then redirect to thank-you screen
  if (alreadyRegistered && !thankYouScreenRequested) {
    const router: Router = inject(Router);
    const basePath = `/${childRoute.params['selectedLanguage']}/${childRoute.params['showId']}`;
    return router.navigate([basePath + '/' + RoutePaths.THANK_YOU]);
  }

  // if user not registered and thank-you screen requested
  // then redirect to the welcome screen
  if (!alreadyRegistered && thankYouScreenRequested) {
    const router: Router = inject(Router);
    const basePath = `/${childRoute.params['selectedLanguage']}/${childRoute.params['showId']}`;
    return router.navigate([basePath + '/' + RoutePaths.WELCOME]);
  }

  return true;
};

/**
 * Route guard to check if registration started when accessing registration routes.
 *
 * If any route except about-you (first registration screen) is accessed and
 * registration is not started, then redirect to about-you page.
 *
 * @export
 * @param {ActivatedRouteSnapshot} childRoute
 * @return {*}
 */
export function checkRegistrationStarted(childRoute: ActivatedRouteSnapshot) {
  const registrationService = inject(RegistrationService);
  const firstScreenRequested = childRoute.routeConfig!.path === RoutePaths.ABOUT_YOU;
  const registrationStarted = registrationService.user.firstname !== null;
  // if registration is not started and first screen in registration process is not requested
  // then redirect to first screen
  if (!firstScreenRequested && !registrationStarted) {
    const router: Router = inject(Router);
    const basePath = `/${childRoute.params['selectedLanguage']}/${childRoute.params['showId']}`;
    return router.navigate([basePath + '/' + RoutePaths.REGISTRATION + '/' + RoutePaths.ABOUT_YOU]);
  }
  return true;
};
