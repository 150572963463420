import {Component, HostBinding} from '@angular/core';
import {StoryblokImageDirective} from 'src/app/directives/storyblok-image.directive';
import {RegistrationService} from 'src/app/services/registration.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {UrlParamsService} from 'src/app/services/url-params.service';
import {ThankYouScreenStoryblok} from 'src/app/types/storyblok-component-types';
import {downloadURI, isAndriodPlatform, isApplePlatform} from 'src/app/utils/utils';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
/**
 *
 * @export
 * @class ThankYouComponent
 */
export class ThankYouComponent {
  protected content!: ThankYouScreenStoryblok;
  // protected qrCode: string = '1234567890';
  protected qrCodeImgSrc!: string;
  userId: string | null;

  /**
   * Creates an instance of ThankYouComponent.
   * @param {StoryblokService} storyblokService
   * @param {UrlParamsService} urlParamsService
   */
  constructor(protected storyblokService: StoryblokService,
      urlParamsService: UrlParamsService) {
  // get screen content
    this.content = this.storyblokService.typedContent.ThankYouScreen![0];
    // // subscribe to content changes
    this.storyblokService.contentLoaded.subscribe(() =>
      this.content = this.storyblokService.typedContent.ThankYouScreen![0],
    );
    this.userId = localStorage.getItem(urlParamsService.urlParams.showId);
    this.qrCodeImgSrc = `${environment.qrUrl}/${this.userId}.png`;
  }

  /**
   * check whether we're on an Android device
   * @return {*}  {boolean}
   * @memberof AccountPageComponent
   */
  protected get showAddToGoogleWallet(): boolean {
    return isAndriodPlatform();
  }

  /**
   * check whether we're on an Apple device
   * @return {*}  {boolean}
   */
  protected get showAddToAppleWallet(): boolean {
    return isApplePlatform();
  }

  /**
   * handler for adding google wallet
   */
  protected onAddToGoogleWallet(): void {
    const url = `${environment.api}/wallet-google/?qr=${this.userId}`;
    downloadURI(url);
  }

  /**
   * handler for adding to apple wallet
   * @protected
   */
  protected onAddToAppleWallet(): void {
    const url = `${environment.api}/wallet-apple/?qr=${this.userId}`;
    downloadURI(url);
  }

  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('style.background-image')
  protected get bgImage(): string {
    const storyblokImageServiceParams = '/m/1900x0/filters:quality(70)';
    return `url("${this.content.HeroImage.filename + storyblokImageServiceParams}")`;
  }
}
