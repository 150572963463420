/* eslint-disable max-len */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packagejson from '../../package.json';

export const environment = {
  api: 'https://stage-backend-api.fordautoshows.com',
  appVersion: packagejson.version + '-stage',
  storyblokToken: 'Tt0P9311TbjtaAQjD7wZSAtt',
  ga: 'G-7PDDGLHWCC',
  url: 'https://stage.fordautoshows.com/reg/',
  sso: {
    googleUrl: 'https://fordautoshows-connected-platform-api-dev.fordautoshows.com/google',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyDLulLjVzxJYR9dq_Bcvg6mq8f81bZywjw',
  qrUrl: 'https://stage-qr-generator.s3.amazonaws.com',
  version: packagejson.version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
