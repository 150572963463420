
<app-loading-overlay></app-loading-overlay>
<app-error-notification></app-error-notification>
<app-language-selection-bar></app-language-selection-bar>
<app-progressbar></app-progressbar>

@if(useCustomRouter) {
    <div class="screen-container">
        <app-custom-router></app-custom-router>
    </div>
} 
@else {
    <div class="screen-container" [@routerAnimation]="getRouteAnimation(router)">
        <router-outlet #router="outlet"></router-outlet>
    </div>
}