import {Component} from '@angular/core';

@Component({
  selector: 'app-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
/**
 *
 * @export
 * @class SpinnerComponent
 */
export class SpinnerComponent {
}
