<input
    type="radio"
    [id]="content.Id"
    [value]="content.Id"
    [formControl]="formControl"
    [checked]="formControl.value === content.Id"
    name={{content.Id}}
>
<label
    [for]="content.Id">
    {{content.Label}}
</label>
@if(showError) {
    <div class="invalid-feedback">
        {{content.Error}}
    </div>
}