<div class="error-panel">
    <!-- <img src="../../../assets/img/alert.svg"> -->
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.799 1.75L18.1603 14.5C18.7376 15.5 18.0159 16.75 16.8612 16.75H2.13878C0.984084 16.75 0.262396 15.5 0.839746 14.5L8.20096 1.75C8.77831 0.75 10.2217 0.75 10.799 1.75Z" stroke="#fff"/>
        <line x1="9.5" y1="14.5" x2="9.5" y2="12.5" stroke="#fff"/>
        <line x1="9.5" y1="10.5" x2="9.5" y2="4.5" stroke="#fff"/>
    </svg>
        
    {{errorMessage}}
</div>
