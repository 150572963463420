<div class="hero" style.background-image="url('{{content.HeroImage.filename}}')" appStoryblokImage>
    <div class="h1">{{content.Header}}</div>
    <div class="h2">{{content.Subheader}}</div>
</div>

<div class="buttons">
    <button 
        appStoryblokButton buttonId="ssoLogin"
        (click)="onGoogleRegister()">
    </button>
    <button 
        appStoryblokButton buttonId="manualLogin"
        (click)="onManualRegister()">
    </button>
</div>

<div class="footer" [innerHTML]="storyblokService.renderMarkup(content.TermsFooter)"></div>
