{
  "name": "fordautoshows-connected-plaform-app",
  "version": "3.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "storyblok:generate-types": "npx storyblok pull-components --space 297958 && npx storyblok generate-typescript-typedefs --sourceFilePaths ./components.297958.json --destinationFilePath ./src/app/types/storyblok-component-types.d.ts"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.1",
    "@angular/common": "^18.1.1",
    "@angular/compiler": "^18.1.1",
    "@angular/core": "^18.1.1",
    "@angular/forms": "^18.1.1",
    "@angular/platform-browser": "^18.1.1",
    "@angular/platform-browser-dynamic": "^18.1.1",
    "@angular/router": "^18.1.1",
    "@easepick/core": "^1.2.1",
    "@easepick/datetime": "^1.2.1",
    "@easepick/lock-plugin": "^1.2.1",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@ng-select/ng-select": "^13.5.0",
    "ngx-mask": "^18.0.0",
    "rxjs": "~7.8.0",
    "storyblok": "^3.32.3",
    "storyblok-js-client": "^5.10.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.1",
    "@angular-eslint/builder": "18.1.0",
    "@angular-eslint/eslint-plugin": "18.1.0",
    "@angular-eslint/eslint-plugin-template": "18.1.0",
    "@angular-eslint/schematics": "18.1.0",
    "@angular-eslint/template-parser": "18.1.0",
    "@angular/cli": "~18.1.1",
    "@angular/compiler-cli": "^18.1.1",
    "@types/google.maps": "^3.55.12",
    "@types/jasmine": "~4.3.0",
    "@types/node": "^20.14.11",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "eslint": "8.57.0",
    "eslint-config-google": "^0.14.0",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "typescript": "~5.4.5"
  }
}
