import {Component} from '@angular/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {WelcomeScreenStoryblok} from 'src/app/types/storyblok-component-types';
import {SingleSignOnService} from 'src/app/services/single-sign-on.service';
import {ActivatedRoute} from '@angular/router';
import {RegistrationService} from 'src/app/services/registration.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
/**
 *
 * @export
 * @class WelcomeComponent
 */
export class WelcomeComponent {
  protected content!: WelcomeScreenStoryblok;

  /**
   * Creates an instance of WelcomeComponent.
   * @param {NavigationService} navigationService
   * @param {SingleSignOnService} singleSignOnService
   * @param {RegistrationService} registrationService
   * @param {StoryblokService} storyblokService
   */
  constructor(public navigationService: NavigationService,
    public singleSignOnService: SingleSignOnService,
    registrationService: RegistrationService,
    public storyblokService: StoryblokService) {
    this.content = this.storyblokService.typedContent.WelcomeScreen[0];
    this.storyblokService.contentLoaded.subscribe(() =>
      this.content = this.storyblokService.typedContent.WelcomeScreen[0],
    );

    // check if the user is already logged in via SSO
    if (registrationService.ssoComplete) {
      navigationService.next();
    }
  }

  /**
   *
   */
  protected onManualRegister(): void {
    this.navigationService.next();
  }

  /**
   *
   */
  protected onGoogleRegister(): void {
    this.singleSignOnService.loginGoogle();
  }
}
