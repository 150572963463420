import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {AbstractControlComponent, componentProvider} from '../input-controls/abstract-control/abstract-control.component';
import {easepick} from '@easepick/core';
import {DateTime} from '@easepick/datetime';
import {LockPlugin} from '@easepick/lock-plugin';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  providers: componentProvider,
})
/**
 *
 * @export
 * @class DatePickerComponent
 */
export class DatePickerComponent extends AbstractControlComponent implements OnInit {
  @Input() activeDates: string[] = [];

  /**
   * Creates an instance of DatePickerComponent.
   * @param {ElementRef} elementRef
   */
  constructor(protected override elementRef: ElementRef) {
    super(elementRef);
  }

  /**
   *
   */
  override ngOnInit(): void {
    // get min and max dates
    const picker = this.initDatePicker();

    // set initial value
    if (this.formControl.value) {
      picker.setDate(new DateTime(new Date(this.formControl.value)));
    }
    // subscribe to form control changes
    this.formControl.valueChanges.subscribe((value) => {
      if (value) {
        picker.setDate(new DateTime(new Date(value)));
      }
    });
  }

  /**
   *
   * @private
   * @return {*}
   */
  private initDatePicker() {
    const {minDate, maxDate} = this.getMinMaxDates();
    const activateDateTimes = this.activeDates.map((d) => {
      return new DateTime(new Date(d));
    });
    const container = this.elementRef.nativeElement.querySelector('.datepicker-container');
    // eslint-disable-next-line new-cap
    const picker = new easepick.create({
      element: container,
      css: [
        'assets/css/@easepick/bundle@1.2.1/dist/index.css',
      ],
      inline: true,
      firstDay: 0,
      plugins: [LockPlugin],
      LockPlugin: {
        minDate,
        maxDate,
        inseparable: true,
        filter(date, picked) {
          return !(date as DateTime).inArray(activateDateTimes, '[)');
        },
      },
    });
    picker.on('select', (e) => {
      const selectedDate = e.detail.date;
      // find active date
      // HACK until we can use proper date stamps
      const activeDate = this.activeDates.find((d: string) => {
        const date = new DateTime(new Date(d));
        return date.isSame(selectedDate);
      });
      this.formControl.setValue(activeDate);
    });
    return picker;
  }

  /**
   *
   * @private
   * @return {*}
   */
  private getMinMaxDates() {
    const datesAsTimeInts = this.activeDates.map((d) => {
      return new Date(d).getTime();
    });
    const minDate = new Date(Math.min(...datesAsTimeInts));
    const maxDate = new Date(Math.max(...datesAsTimeInts));
    return {minDate, maxDate};
  }
}
