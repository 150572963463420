<div class="h1">{{content.Header}}</div>

<div class="page-layout">

    <div class="column left">
        <div class="title1">{{content.Title}}</div>
        <!-- refactor to waiver component ? -->
        <div class="waiver-container">
            <div class="waiver max-height-310" [ngClass]="{'show-waiver-error': showWaiverError}"
                [innerHTML]="storyblokService.renderMarkup(content.Waiver)">
            </div>
            @if(showWaiverError) {
                <div class="invalid-feedback">
                    {{content.WaiverError}}
                </div>
            }
        </div>
        <!-- waiver component ? -->
        <div class="title2">{{content.WaiverConfirmation}}</div>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.signature)"
            [formControl]="getFormControl(formFields.signature)">
        </app-input-text-field>
    </div>
    
    <!-- Minors Waiver Section -->
    <div class="column mid">
        <div class="title1">{{content.MinorsWaiverTitle}}</div>

        <!-- Yes no tab group for 'do you have minors question' -->
        <app-tab-group (tabSelected)="onMinorsTabSelected($event)">
            <tab [content]="getInputFieldContent('hasMinorYes')"></tab>
            <tab [content]="getInputFieldContent('hasMinorNo')"></tab>
        </app-tab-group>

        <!-- minors input fields -->
        <app-input-text-field
            [content]="getInputFieldContent(formFields.minor1)"
            [formControl]="getFormControl(formFields.minor1)">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.minor2)"
            [formControl]="getFormControl(formFields.minor2)">
        </app-input-text-field>
        <app-input-text-field
            [content]="getInputFieldContent(formFields.minor3)"
            [formControl]="getFormControl(formFields.minor3)">
        </app-input-text-field>

        <div class="minors-wavier">{{content.MinorsWavierConfirmation}}</div>
    </div>

    <div class="column right">
        <div class="hero-image" style.background-image="url('{{content.HeroImage.filename}}')" appStoryblokImage>
        </div>
    </div>

    <div class="submit">
        <button class="btn" type="button" (click)="onSubmit()">
            {{storyblokService.typedContent.UILabels![0].SubmitBtn}}
        </button>
    </div>

</div>