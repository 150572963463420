import {EventEmitter, Injectable} from '@angular/core';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from 'src/environments/environment';

export interface AutoCompleteSelection {
  street: string;
  apt: string;
  city: string;
  state: string;
  zip: string;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service for loading Google Places API and injecting Autocomplete UI
 */
export class GooglePlacesService {
  private readonly API_KEY: string = environment.GOOGLE_MAPS_API_KEY;
  public onAutoCompleteSuggestionSelected: EventEmitter<AutoCompleteSelection> = new EventEmitter();
  private autocomplete!: google.maps.places.Autocomplete;
  private inputElement!: HTMLInputElement;

  /**
   * Load Google Places API
   * @return {*}  {string}
   */
  public async loadAPI(): Promise<void> {
    const loader = new Loader({
      apiKey: this.API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    await loader.importLibrary('places');
  }

  /**
   * Initialize Google Places Autocomplete on input element
   * @param {HTMLInputElement} inputElement
   */
  public initGoogleAddressAutoComplete(inputElement: HTMLInputElement): void {
    const options = {
      componentRestrictions: {country: 'us'},
      fields: ['address_components'],
      strictBounds: false,
    };
    this.inputElement = inputElement;
    this.autocomplete = new google.maps.places.Autocomplete(inputElement, options);
    this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this));
  }

  /**
   * When user selects a suggestion from the autocomplete dropdown
   * @private
   */
  private onPlaceChanged() {
    const place = this.autocomplete.getPlace();
    const addressComponents = place.address_components!;
    const subpremise = addressComponents.find((component) => component.types.includes('subpremise'));
    const city = addressComponents.find((component) => component.types.includes('locality')) ||
                 addressComponents.find((component) => component.types.includes('sublocality'));
    const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'));
    const zip = addressComponents.find((component) => component.types.includes('postal_code'));
    this.onAutoCompleteSuggestionSelected.emit({
      street: this.inputElement.value,
      state: state?.short_name || '',
      apt: subpremise?.long_name || '',
      city: city?.long_name || '',
      zip: zip?.long_name || '',
    });
  };
}
