import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ButtonStoryblok} from '../types/storyblok-component-types';
import {StoryblokService} from '../services/storyblok.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appStoryblokButton]',
  exportAs: 'appStoryblokButton',
  standalone: true,
})
/**
 * Creates a button dom element from a Storyblok button
 * Instead of having to do something like this in your templates:
 * ```
 *  @let ssoLoginBtn = getButton('ssoLogin');
 *  @if (ssoLoginBtn) {
 *     <button>
 *        {{ssoLoginBtn.Label}}
 *     </button>
 *  }
 * ```
 *
 * You can do this:
 * ```
 *  <button appStoryblokButton buttonId="ssoLogin">
 *  </button>
 * ```
 *
 * @export
 * @class StoryblokButtonDirective
 */
export class StoryblokButtonDirective implements OnInit, OnDestroy {
  @Input() buttonId: string | undefined;
  private sub: Subscription;
  /**
   * Creates an instance of StoryblokButtonDirective.
   */
  constructor(
    private storyblokService: StoryblokService,
     private el: ElementRef) {
    this.sub = this.storyblokService.contentLoaded.subscribe(() =>
      this.initButton(),
    );
  }

  /**
   */
  ngOnInit() {
    this.initButton();
  }

  /**
   */
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   *
   */
  private initButton() {
    const sbButton = this.getButtonData();
    if (!sbButton) {
      this.removeButton();
      return;
    }
    this.el.nativeElement.innerHTML = sbButton.Label;
  }

  /**
   * @private
   * @return {*}  {(ButtonStoryblok | undefined)}
   */
  private getButtonData(): ButtonStoryblok | undefined {
    if (!this.buttonId) return;
    return this.storyblokService.getButton(this.buttonId);
  }

  /**
   * @private
   */
  private removeButton() {
    this.el.nativeElement.parentElement.removeChild(this.el.nativeElement);
  }
}
