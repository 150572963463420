import {EventEmitter, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {default as StoryblokClient, RichtextResolver} from 'storyblok-js-client';
import {UrlParamsService} from './url-params.service';
import {ButtonStoryblok, ShowContentStoryblok} from '../types/storyblok-component-types';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/** */
export class StoryblokService {
  public contentLoaded: EventEmitter<ShowContentStoryblok> = new EventEmitter();
  public loading: boolean = true;
  public content!: any;// ShowContentStoryblok;
  public typedContent!: ShowContentStoryblok;

  private buttons: ButtonStoryblok[] = [];
  private resolver = new RichtextResolver();
  private client = new StoryblokClient({
    accessToken: environment.storyblokToken,
  });

  /**
   * Creates an instance of StoryblokService.
   * @param {UrlParamsService} urlParamsService
   */
  constructor(private urlParamsService: UrlParamsService,
    private router: Router) {}

  /**
   * Gets a story
   * @param {string} language - The language
   * @return {Promise<Story>} - Resolves with the story
   */
  public async getContentStory(language: string): Promise<any> {
    this.loading = true;
    const slug = `shows/${this.urlParamsService.urlParams.showId}/content`;

    const {data} = await this.client.getStory(slug, {
      language,
      version: 'draft',
    });
    this.typedContent = data.story.content as ShowContentStoryblok;
    this.content = data.story.content;

    // TODO probably want to automatically recurse through all content
    // and pull about components by type
    this.buttons = [...this.typedContent.WelcomeScreen[0].Buttons!];
    this.loading = false;
    this.contentLoaded.emit(this.typedContent);
  }

  /**
   * render dynamic rich text content
   * @param {any} storyblokHTML
   * @return {any}
   */
  public renderMarkup(storyblokHTML: any) {
    return `${this.resolver.render(storyblokHTML)}`;
  }

  /**
   * Navigate the site to an updated path with the selected language in the path
   * @param {string} lang
   */
  public selectLangage(lang: string): void {
    const selectedLangParamIndex = 1;
    const currentPath = this.router.url.split('/');
    // update selected language component is current path
    currentPath[selectedLangParamIndex] = lang;
    const newPath = currentPath.join('/');
    this.router.navigate([`${newPath}`]);
  }

  /**
   *
   * @param {string} buttonId
   * @return {*}  {(ButtonStoryblok | undefined)}
   */
  public getButton(buttonId: string): ButtonStoryblok | undefined {
    return this.buttons.find((button) => button.Id === buttonId);
  }
}
