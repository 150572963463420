import {Component, Input, numberAttribute, OnInit} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {AbstractControlComponent, componentProvider} from '../abstract-control/abstract-control.component';

@Component({
  selector: 'app-input-select-box',
  standalone: true,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './input-select-box.component.html',
  styleUrl: './input-select-box.component.scss',
  providers: componentProvider,
})
/**
 *
 * @export
 * @class InputSelectBoxComponent
 * @extends {AbstractControlComponent}
 */
export class InputSelectBoxComponent extends AbstractControlComponent implements OnInit {
  @Input({transform: numberAttribute}) visibleItems: number | undefined;

  /**
   *
   */
  override ngOnInit(): void {
    super.ngOnInit();
    this.setVisibleItems();
  }

  /**
   * Kind of hacky, but seems like the only way to get the form control name
   * @return {*}  {(string | null)}
   */
  protected getFormControlName(): string | null {
    const parentFormGroup:any = this.formControl.parent!.controls;
    return Object.keys(parentFormGroup).find((name) => this.formControl === parentFormGroup[name]) || null;
  }

  /**
   * Set the visible items for the select box... used to find the last value
   * after our 'dark UX' exercise...
   * @private
   */
  private setVisibleItems() {
    if (this.visibleItems) {
      const MAX_VISIBLE_ITEMS = 6;
      let maxVisibleItems = Math.min(this.content.Answers.length, MAX_VISIBLE_ITEMS);
      maxVisibleItems = Math.min(maxVisibleItems, this.visibleItems);
      this.elementRef.nativeElement.style.setProperty('--max-items', maxVisibleItems);
    } else {
      this.elementRef.nativeElement.style.setProperty('--max-scroll-host-height', 'auto');
    }
  }
}
