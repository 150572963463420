import {
  animate,
  query,
  style,
  transition,
  trigger,
  group,
} from '@angular/animations';

const animation = '400ms linear';
const options = {optional: true};
/**
   * Angular route transitions kind of suck, this is based on:
   * https://medium.com/@gogakoreli/angular-7-improved-router-transition-animation-5bc4578a2f3a
   * https://github.com/gogakoreli/angular-page-change-animation
   * @export
   * @return {*}
   */
export function routerAnimation() {
  return trigger('routerAnimation', [
    // One time initial load. Move page from left -100% to 0%


    // Previous, slide left to right to show left page
    transition(':decrement', [
      // set new page X location to be -100%
      query(
          ':enter',
          style({
            transform: 'translateX(-100vw)',
          }),
          options,
      ),

      group([
        // slide existing page from 0% to 100% to the right
        query(
            ':leave',
            animate(
                animation,
                style({
                  transform: 'translateX(100vw)',
                }),
            ),
            options,
        ),
        // slide new page from -100% to 0% to the right
        query(
            ':enter',
            animate(
                animation,
                style({
                  opacity: 1,
                  transform: 'translateX(0%)',
                }),
            ),
            options,
        ),
      ]),
    ]),

    // Next, slide right to left to show right page
    transition(':increment', [
      // set new page X location to be 100%
      query(
          ':enter',
          style({
            transform: 'translateX(100vw)',
          }),
          options,
      ),

      group([
        // slide existing page from 0% to -100% to the left
        query(
            ':leave',
            animate(
                animation,
                style({
                  transform: 'translateX(-100vw)',
                }),
            ),
            options,
        ),
        // slide new page from 100% to 0% to the left
        query(
            ':enter',
            animate(
                animation,
                style({
                  opacity: 1,
                  transform: 'translateX(0%)',
                }),
            ),
            options,
        ),
      ]),
    ]),
  ]);
}

